import axios from 'axios';

// eslint-disable-next-line no-undef
const apiBaseURL = 'https://api-stage.get.timeshifter.com';

export const paymentsSubscriptions = async ({
  email,
  type,
  ruleId,
  authCode,
  mmCardNumber,
  membershipId,
}) => {
  try {
    const { data } = await axios.post(`${apiBaseURL}/payments/subscriptions`, {
      email,
      type,
      ruleId,
      authCode,
      mmCardNumber,
      membershipId,
    });
    return data;
  } catch (error) {
    console.error('Error during payment subscriptions:', error);
    throw error;
  }
};
