import axios from 'axios';

// eslint-disable-next-line no-undef
const apiBaseURL = 'https://api.get.timeshifter.com';

export const userVerification = async ({ membershipId, surname }) => {
  try {
    const { data } = await axios.post(
      `${apiBaseURL}/mileage-plus/user-verification`,
      {
        membershipId,
        surname,
      }
    );
    return data;
  } catch (error) {
    console.error('Error during user verification:', error);
    throw error;
  }
};

export const userConfirmation = async ({ membershipId, surname, token }) => {
  try {
    const { data } = await axios.post(
      `${apiBaseURL}/mileage-plus/user-confirmation`,
      {
        membershipId,
        surname,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error('Error during user confirmation:', error);
    throw error;
  }
};
